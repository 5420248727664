import React from "react";
import Slider from "react-slick";
import { Box, Container } from "@mui/material";
import './style.css';

const Carousel = ({ images }: any) => {
  if (!images || images.length === 0) {
    return <p>No images available</p>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: images.length <= 1 ? 1 : 2.3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: images.length <= 1 ? 1 : 2.3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <Container maxWidth="lg" sx={{ my: '60px' }}>
      <Slider {...settings} className="carousel-pd">
        {images.map((img: any, index: any) => (
          <Box key={index} className="carouselImageContainer">
            <img src={`${img.path}`} alt={`Imagem ${index + 1}`} />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default Carousel;


