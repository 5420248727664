import React, { useState } from 'react';
import { Grid, Typography, Box, Button, Container } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import './style.css'; // Adicione este arquivo CSS para os estilos de transição

const Details = ({ property }: any) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const truncatedDescription = property.description.length > 300
        ? property.description.substring(0, 300) + '...'
        : property.description;

    return (
        <Container maxWidth="lg" sx={{ my: '60px' }}>
            <Box sx={{ backgroundColor: '#000', color: '#6cde04', padding: '20px' }}>
                <Grid container spacing={2} justifyContent="space-between">
                    {/* Left Section */}
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                            <Grid item xs={3} sx={{ display: "grid", justifyContent: "center" }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/svg/area.svg`} alt="Área" className="img-details" />
                                <Typography color="white" align='center'> {property.area}m² </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: "grid", justifyContent: "center" }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/svg/bathroom.svg`} alt="Banheiro" className="img-details" />
                                <Typography color="white" align='center'> {property.suites} suítes </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: "grid", justifyContent: "center" }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/svg/bed.svg`} alt="Quartos" className="img-details" />
                                <Typography color="white" align='center'> {property.bedrooms} quartos </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ display: "grid", justifyContent: "center" }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/svg/car.svg`} alt="Vagas" className="img-details" />
                                <Typography color="white" align='center'> {property.parking_spots} vagas </Typography>
                            </Grid>
                        </Grid>

                        <Typography color="white" variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                            {property.friendly_title}
                        </Typography>
                        <Typography color="white" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                            Sobre este Imóvel
                        </Typography>
                        <CSSTransition
                            in={expanded}
                            timeout={300}
                            classNames="description"
                            unmountOnExit
                        >
                            <Typography color="white" variant="body1" sx={{ marginTop: '10px' }}>
                                {property.description}
                            </Typography>
                        </CSSTransition>
                        {!expanded && (
                            <Typography color="white" sx={{ marginTop: '10px' }}>
                                {truncatedDescription}
                            </Typography>
                        )}
                        {property.description.length > 300 && (
                            <Typography color="white" sx={{ marginTop: '10px', cursor: 'pointer', fontWeight: 'bold' }} onClick={toggleExpanded}>
                                {expanded ? "Mostrar Menos -" : "Exibir Mais +"}
                            </Typography>
                        )}
                    </Grid>

                    {/* Right Section */}
                    <Grid item xs={12} md={4}>
                        <Box className="card-details">
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/svg/local.svg`} alt="Localização" style={{ marginBottom: '20px' }} />
                                <Typography variant="h6">{property.city} - {property.state_abbreviation}</Typography>
                            </Box>
                            <Typography variant="h5" sx={{ marginTop: '10px' }}>
                                Valor: <br /> R$ {parseFloat(property.sale_price).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Typography>
                            <Button variant="contained" className='button-details'>
                                Entrar em contato
                            </Button>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Container>
    );
};

export default Details;
