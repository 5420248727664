import React from 'react';
import { Box, Container, Typography, Link, Divider, Grid } from '@mui/material';
import { Facebook, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';
import LogoBranca from '../../../assets/images/logo-branca.png'

const Footer = () => {

    const socialLinks = [
        { Icon: Instagram, url: 'https://www.instagram.com/vextimob/' },
        { Icon: LinkedIn, url: 'https://www.linkedin.com/company/vext-imob/' },
        { Icon: YouTube, url: 'https://www.youtube.com/channel/UCR0DVf29XU-SCIBwHXGSaMw' }
    ];

    return (
        <Box component="footer" sx={{ bgcolor: 'black', color: 'white' }}>
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" component="div" sx={{ fontWeight: '700' }}>
                            <img src={LogoBranca} alt="Logo VEXT" width={'80px'} />
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={5} sx={{ display: {xs:'grid', sm: 'flex'}, justifyContent: 'center', gap: 2, flexWrap: 'wrap', textAlign: 'center' }}>
                        {['Link One', 'Link Two', 'Link Three', 'Link Four', 'Link Five'].map((link) => (
                            <Link
                                key={link}
                                href="#"
                                color="inherit"
                                underline="none"
                            >
                                {link}
                            </Link>
                        ))}
                    </Grid> */}
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, gap: 2, flexWrap: 'wrap' }}>
                        {socialLinks.map((link, index) => (
                            <Link key={index} href={link.url} target="_blank" color="inherit">
                                <link.Icon />
                            </Link>
                        ))}
                    </Grid>
                </Grid>

                <Divider sx={{ bgcolor: 'grey.700', my: 3 }} />

                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: { xs: 1, sm: 0 },
                }}>

                    <Box sx={{ display: { xs: 'grid', sm: 'flex' }, gap: 2, textAlign: 'center' }}>
                        {['Políticas de Cookies'].map((link) => (
                            <Link key={link} href="#" color="inherit" underline="none">
                                {link}
                            </Link>
                        ))}
                    </Box>
                    <Typography variant="caption" component="div" sx={{ mt: 2 }}>
                        © 2024 VEXT. Todos os direitos reservados.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
