import React, { useState, useEffect, useRef } from "react";
import './style.css';
import NavBar from "../../components/NavBar";
import { ThemeProvider } from "@mui/material";
import monochrome from "../../Themes/Monochrome";
import { Header } from "../../sections/Home/Header";
import { Matchmaker } from "../../sections/Home/Matchmaker";
import Testimonials from "../../sections/Home/Testimonials";
import { Contact } from "../../sections/Home/Contact";
import CompanyLocations from "../../sections/Home/CompanyLocations";
import Footer from "../../sections/Home/Footer";
import Carousel from "../../sections/Home/Carousel";
import api from "../../services/api";
import Video from "../../sections/Home/Video";
import Comercial from "../../sections/Home/Comercial";

const Home = () => {
  const [carouselData, setCarouselData] = useState([]);
  const carouselRef:any = useRef(null);

  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await api.get('list-sections'); 
        setCarouselData(response.data); 
      } catch (error) {
        console.error("Erro ao buscar dados dos carouséis:", error);
      }
    };

    fetchCarousels();
  }, []);

  return (
    <ThemeProvider theme={monochrome}>
      <NavBar carouselRef={carouselRef} />
      <Header />
      {/* <Video/> */}
      <Comercial/>
      <Matchmaker />
      {carouselData.map((carousel:any) => (
        <div ref={carouselRef} key={carousel.id}>
          <Carousel 
            title={carousel.name} 
            subTitle={carousel.friendly_title}
            id={carousel.id}
          />
        </div>
      ))}
      <Testimonials />
      <Contact />
      <CompanyLocations />
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
