import React from 'react';
import { AppBar, Toolbar, Container, Typography, Button, Box, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import Logo from '../../assets/images/logo.svg';
import './style.css';
import defaultTheme from '../../Themes/Default';
import { ButtonAnimated } from '../ButtonAnimated';

const NavBar = ({ carouselRef }:any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleScrollToCarousel = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="static" className="appBar">
        <Container maxWidth="lg" className="container">
          <Toolbar disableGutters className="toolbar">
            <Box className="logoBox">
              <Typography variant="h4" component="div" className="logoTypography">
                <img src={Logo} alt="Logo" className="logoImage" />
              </Typography>
            </Box>
            {!isMobile && (
              <Box className="navButtons">
                <Button color="inherit" className="navButton" onClick={handleScrollToCarousel}>Imóveis</Button>
                <Button color="inherit" className="navButton">Contato</Button>
                <Button color="inherit" className="navButton">Parceiros</Button>
                <Button color="inherit" className="navButton">Sobre Nós</Button>
              </Box>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open('https://wa.me/+5547991683239?text=Olá! Gostaria de mais informações.', '_blank')}
            >
              Saiba Mais
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default NavBar;
