// src/App.tsx
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css'
import Home from "./pages/Home";
import PropertyDetails from "./pages/PropertyDetails";


export default function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/"  element={<Home />} />
          <Route path="/detalhes-do-imovel/:secureId"  element={<PropertyDetails />} />
        </Routes>
    </BrowserRouter>
  );
}
