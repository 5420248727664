import React from 'react';
import Slider from 'react-slick';
import { Box, Container, Typography, Paper } from '@mui/material';
import './style.css';

const Comercial = () => {
  const slides = [
    {
      title: "Preços mais Acessíveis",
      description: "Imóveis comprados na planta geralmente são mais baratos do que os prontos para morar, permitindo que você compre a um preço menor e se beneficie da valorização futura.",
      image: "https://revistaoe.com.br/wp-content/uploads/2021/11/The-Wave-3.jpg"
    },
    {
      title: "Facilidade de Pagamento",
      description: "Condições de pagamento facilitadas durante a construção, permitindo que o comprador parcele a entrada e outras parcelas diretamente com a construtora.",
      image: "https://rafaelcassio.com.br/media/rafaelcassio/2023/10/investimento-em-imo%CC%81veis-em-pic%CC%A7arras.webp"
    },
    {
      title: "Personalização",
      description: "Ao adquirir na planta, você pode personalizar o imóvel conforme suas preferências, desde acabamentos até modificações na planta.",
      image: "https://casaprimecomvoce.com.br/wp-content/uploads/2021/04/Banner-Rotativo-1-1080x675.jpg"
    },
    {
      title: "Valorização do Imóvel",
      description: "Historicamente, imóveis na planta apresentam significativa valorização até a entrega, impulsionados pelo desenvolvimento do projeto e crescimento econômico da região.",
      image: "https://img.olx.com.br/images/16/163429652868513.jpg"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box className="benefits-section" sx={{ overflow: 'hidden' }}>
      <Container maxWidth="lg" sx={{ position: 'relative', padding: '0 !important' , mb: 4}}>
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <Box key={index} sx={{ p: 0 }}>
              <Paper elevation={3} sx={{borderRadius: '0', height: '600px', backgroundImage: `url(${slide.image})`, backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <Typography variant="h5" component="h3" gutterBottom sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px 20px'}}>
                  {slide.title}
                </Typography>
                <Typography variant="body1" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px 20%'}}>
                  {slide.description}
                </Typography>
              </Paper>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Comercial;
