import './style.css'
export function ButtonAnimated({children}:any) {

    return (
        <div className="btnAnimatedContainer">
            <div className="btnAnimatedCenter">
                <button className="btnAnimated">
                    <svg width="115px" height="48px" viewBox="0 0 115 48" className="border">
                        <polyline points="114,1 114,47 1,47 1,1 114,1" className="bg-line" />
                        <polyline points="114,1 114,47 1,47 1,1 114,1" className="hl-line" />
                    </svg>
                    <span>{children}</span>
                </button>
            </div>
        </div>
    )
}