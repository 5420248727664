import React, { useState, useEffect, useRef } from "react";
import './style.css';
import NavBar from "../../components/NavBar";
import { ThemeProvider } from "@mui/material";
import monochrome from "../../Themes/Monochrome";
import Footer from "../../sections/Home/Footer";
import api from "../../services/api";
import Carousel from "../../sections/PropertyDetails/Carousel";
import MoreCarousel from "../../sections/PropertyDetails/MoreCarousel";
import { useParams } from "react-router-dom";
import Details from "../../sections/PropertyDetails/Details";

const PropertyDetails = () => {
  const { secureId } = useParams();
  const [propertyData, setPropertyData]: any = useState(null); // Inicializar como null
  const [carouselData, setCarouselData]:any = useState([]);
  
  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await api.get(`list-property/${secureId}`);
        setPropertyData(response.data.property[0]);
      } catch (error) {
        console.error("Erro ao buscar dados dos carouséis:", error);
      }
    };

    if (secureId) {
      fetchCarousels();
    }
  }, [secureId]);



  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await api.get('list-sections'); 
        setCarouselData(response.data[0]); 
      } catch (error) {
        console.error("Erro ao buscar dados dos carouséis:", error);
      }
    };

    fetchCarousels();
  }, []);

  return (
    <ThemeProvider theme={monochrome}>
      <NavBar />
      {propertyData && Array.isArray(propertyData.images) && (
        <Carousel images={propertyData.images} />
      )}
      {propertyData && <Details property={propertyData} />}
      <MoreCarousel 
            title="Mais imóveis como este" 
            id={carouselData.id}
          />
      <Footer />
    </ThemeProvider>
  );
};

export default PropertyDetails;
